import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { format } from 'date-fns';
import brLocale from "date-fns/locale/pt-BR";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { 
    isPermiteCadastroAlunosProvasEspeciais, 
    isPeriodoInserirNotaFase2,
} from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import AlertaEscola, { 
    escolaComCadastroAlunoDisponivel, 
    escolaJaCadastrouTodosOsAlunosDoNivel,
    escolaNaoInformouAlunosPrevistosParaNivel,
    escolaPodeCadastrarMaisAlunosNoNivel,
} from './AlertaEscola';

import AlunoService from '../../services/Aluno';
import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';
import CheckBox from '../../components/Inputs/CheckBox/CheckBox';

import {
    optionsAnoLetivo,
    optionsNivel,
    optionsSexo,
    optionsRaca,
    optionsTipoProva
} from '../../variables/Enums/Aluno';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';
import DialogConfirm from '../../forms/Dialog/DialogConfirm';

import {
    isBlank,
    isBlankHelperText,
    isNotaInvalida,
    isNotaHelperText,
    isNameCompoundInvalid,
    isNameCompoundHelperText,
    foraDaDataNascimentoPrevista,
    isForaDaDataNascimentoPrevista,
    isDateTimeHelperText,
    isDateTimeInvalid
} from '../../helper/ValidationHelper';
import { formatInDate, parseDate } from '../../helper/DateHelper';
import { numberMask, nameMask } from '../../helper/MaskHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import Layout from '../../layouts/private/Private';
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';

export class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            escola: null,
            aluno: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                mecCode: this.props.match.params.inep,
                name: '',
                sexo: '',
                raca: '',
                dataNascimento: '',
                anoLetivo: '',
                anoLetivoOriginal: null,
                nivel: '',
                tipoProva: 'NORMAL',
                notaFase1: null,
                notaFase2: null,
                exibirOptionsTipoProva: false
            },
            errors: {
                name: false,
                sexo: false,
                dataNascimento: false,
                anoLetivo: false,
                tipoProva: false,
                notaFase1: false,
                notaFase2: false,
            },
            helpers: {
                name: null,
                sexo: null,
                dataNascimento: null,
                anoLetivo: null,
                tipoProva: null,
                notaFase1: null,
                notaFase2: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id || this.props.match.params.inep ? true : false,
            history: {
                path: null,
                state: null
            },
        };
    }

    // INPUTS
    handleChange = e => {        
        this.setState({
            aluno: {
                ...this.state.aluno,
                [e.target.name]: e.target.name === 'notaFase1' || e.target.name === 'notaFase2' ? numberMask(e.target.value) : e.target.name === 'name' ? nameMask(e.target.value) : e.target.value,
                nivel: e.target.name === 'anoLetivo' ? e.target.value === 'SEGUNDO' || e.target.value === 'TERCEIRO' ? 'MIRIM_1' 
                        : e.target.value === 'QUARTO' || e.target.value === 'QUINTO' ? 'MIRIM_2' 
                            : null : this.state.aluno.nivel,
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        });
    };

    // INPUTS DATE TIME PICKER
    handleChangeDate = (e, name) => {
        this.setState({
            aluno: {
                ...this.state.aluno,
                [name]: e,
            },
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null }
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setDialogConfirm = d => this.DialogConfirm = d;

    verifyChangeNivel = e => {
        const title = "Troca de Nivel";

        const mirimUmSelecionado = e.target.value === 'SEGUNDO' || e.target.value === 'TERCEIRO';
        const mirimDoisSelecionado = e.target.value === 'QUARTO' || e.target.value === 'QUINTO';
        const MirimUm = this.state.aluno.anoLetivoOriginal === 'SEGUNDO' || this.state.aluno.anoLetivoOriginal === 'TERCEIRO';
        const MirimDois = this.state.aluno.anoLetivoOriginal === 'QUARTO' || this.state.aluno.anoLetivoOriginal === 'QUINTO';
        
        if (escolaNaoInformouAlunosPrevistosParaNivel(this.state.escola, 1) && mirimUmSelecionado)
            this.openDialogAlert(title, this.textTrocaNivelNaoInformouParaCadastrar(1), false);
        
        else if (escolaNaoInformouAlunosPrevistosParaNivel(this.state.escola, 2) && mirimDoisSelecionado)
            this.openDialogAlert(title, this.textTrocaNivelNaoInformouParaCadastrar(2), false);
        
        else if (escolaJaCadastrouTodosOsAlunosDoNivel(this.state.escola, 1) && mirimUmSelecionado && (!this.state.aluno.id || MirimDois))
            this.openDialogAlert(title, this.textTrocaNivelNaoPodeCadastrarMais(1), false);
        
        else if (escolaJaCadastrouTodosOsAlunosDoNivel(this.state.escola, 2) && mirimDoisSelecionado && (!this.state.aluno.id || MirimUm))
            this.openDialogAlert(title, this.textTrocaNivelNaoPodeCadastrarMais(2), false);

        else if (escolaPodeCadastrarMaisAlunosNoNivel(this.state.escola, 1) && MirimDois && mirimUmSelecionado)
            this.openDialogAlert(title, this.textTrocaNivelPodeCadastrarMais(2, 1));

        else if (escolaPodeCadastrarMaisAlunosNoNivel(this.state.escola, 2) && MirimUm && mirimDoisSelecionado)
            this.openDialogAlert(title, this.textTrocaNivelPodeCadastrarMais(1, 2));
    }

    textTrocaNivelPodeCadastrarMais = (nivelOriginal, nivelNovo) => `A alteração do Ano Letivo está mudando o Aluno de Mirim ${nivelOriginal} para Mirim ${nivelNovo}. Voce Confirma ?`;
    textTrocaNivelNaoInformouParaCadastrar = (nivel) => `A alteração do Ano Letivo está mudando o Aluno para Mirim ${nivel}, entretanto sua escola não inscreveu alunos no Mirim ${nivel}.`;
    textTrocaNivelNaoPodeCadastrarMais = (nivel) => `A Escola já cadastrou todos os Alunos possíveis no Mirim ${nivel}.`;

    // DIALOG DE ALERTA
    openDialogAlert = (title, text, showConfirm = true) => {
        this.DialogConfirm.setState({
            dialog: {
                open: true,
                title: title,
                text: text,
                loading: false,
                showConfirm: showConfirm,
            }
        });
    }

    handleCancelChangeNivel = () => {
        this.setState({
            aluno: {
                ...this.state.aluno,
                anoLetivo: this.state.aluno.anoLetivoOriginal,
                nivel: this.state.aluno.anoLetivoOriginal === 'SEGUNDO' || this.state.aluno.anoLetivoOriginal === 'TERCEIRO' ? 'MIRIM_1' 
                        : this.state.aluno.anoLetivoOriginal === 'QUARTO' || this.state.aluno.anoLetivoOriginal === 'QUINTO' ? 'MIRIM_2' : null,
            },
        })

        this.DialogConfirm.close();
    }

    // VALIDAÇÕES
    isValid = () => {
        this.setState({
            aluno: { ...this.state.aluno },
            errors: { 
                ...this.state.errors,
                notaFase1: false,
                notaFase2: false, 
            },
            helpers: { 
                ...this.state.helpers, 
                notaFase1: null,
                notaFase2: null, 
            }
        });

        const notaFase1Vazia = (isBlank(this.state.aluno.notaFase1) && !isPeriodoInserirNotaFase2(this.props.contexts));
        const notaFase1Invalida = (isNotaInvalida(this.state.aluno.notaFase1) && !isPeriodoInserirNotaFase2(this.props.contexts))

        const ambasNotasVazias = isBlank(this.state.aluno.notaFase1) && isBlank(this.state.aluno.notaFase2) && isPeriodoInserirNotaFase2(this.props.contexts);
        const umaNotaInvalida = (isNotaInvalida(this.state.aluno.notaFase1) || isNotaInvalida(this.state.aluno.notaFase2)) && isPeriodoInserirNotaFase2(this.props.contexts);

        if (isBlank(this.state.aluno.name) || isNameCompoundInvalid(this.state.aluno.name) || isBlank(this.state.aluno.sexo)
                || isBlank(this.state.aluno.dataNascimento) || foraDaDataNascimentoPrevista(this.state.aluno.dataNascimento) 
                || isBlank(this.state.aluno.anoLetivo) || isBlank(this.state.aluno.tipoProva) || isDateTimeInvalid(this.state.aluno.dataNascimento)
                || ambasNotasVazias || notaFase1Vazia || umaNotaInvalida || notaFase1Invalida) {
            this.setState({
                errors: {
                    name: isBlank(this.state.aluno.name) || isNameCompoundInvalid(this.state.aluno.name) ? true : false,
                    sexo: isBlank(this.state.aluno.sexo) ? true : false,
                    dataNascimento: isBlank(this.state.aluno.dataNascimento) || foraDaDataNascimentoPrevista(this.state.aluno.dataNascimento) || isDateTimeInvalid(this.state.aluno.dataNascimento) ? true : false,
                    anoLetivo: isBlank(this.state.aluno.anoLetivo) ? true : false,
                    tipoProva: isBlank(this.state.aluno.tipoProva) ? true : false,
                    notaFase1: ambasNotasVazias || notaFase1Vazia ? true : umaNotaInvalida || notaFase1Invalida ? isNotaInvalida(this.state.aluno.notaFase1) ? true : false : false,
                    notaFase2: ambasNotasVazias ? true : umaNotaInvalida ? isNotaInvalida(this.state.aluno.notaFase2) ? true : false : false, 
                },
                helpers: {
                    name: isBlank(this.state.aluno.name) ? isBlankHelperText() : isNameCompoundInvalid(this.state.aluno.name) ? isNameCompoundHelperText() : null,
                    sexo: isBlank(this.state.aluno.sexo) ? isBlankHelperText() : null,
                    dataNascimento: isBlank(this.state.aluno.dataNascimento) ? isBlankHelperText() : foraDaDataNascimentoPrevista(this.state.aluno.dataNascimento) ? isForaDaDataNascimentoPrevista() : isDateTimeInvalid(this.state.aluno.dataNascimento) ? isDateTimeHelperText() : null,
                    anoLetivo: isBlank(this.state.aluno.anoLetivo) ? isBlankHelperText() : null,
                    tipoProva: isBlank(this.state.aluno.tipoProva) ? isBlankHelperText() : null,
                    notaFase1: ambasNotasVazias || notaFase1Vazia ? ambasNotasVazias ? "É necessário que uma das notas esteja preenchida." : isBlankHelperText() : umaNotaInvalida || notaFase1Invalida ? isNotaInvalida(this.state.aluno.notaFase1) ? isNotaHelperText(this.state.aluno.notaFase1) : null : null,
                    notaFase2: ambasNotasVazias ? "É necessário que uma das notas esteja preenchida." : umaNotaInvalida ? isNotaInvalida(this.state.aluno.notaFase2) ? isNotaHelperText(this.state.aluno.notaFase2) : null : null,
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();

        if (!formIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const aluno = {
            cdEscola: this.state.aluno.mecCode,
            nmAluno: this.state.aluno.name.toUpperCase().trim().replaceAll(/\s+/g, " "),
            sexo: this.state.aluno.sexo,
            raca: this.state.aluno.raca !== '' ? this.state.aluno.raca : null,
            dataNascimento: formatInDate(this.state.aluno.dataNascimento),
            anoLetivo: this.state.aluno.anoLetivo,
            notaFase1: this.state.aluno.notaFase1 !== '' ? this.state.aluno.notaFase1 : null,
            notaFase2: this.state.aluno.notaFase2 !== '' ? this.state.aluno.notaFase2 : null,
            tipoProva: this.state.aluno.exibirOptionsTipoProva ? this.state.aluno.tipoProva : 'NORMAL'
        }

        let msgSuccess = '';
        let request = '';

        if (this.state.aluno.id) {
            request = AlunoService.edit(this.state.aluno.id, aluno);
            msgSuccess = "Aluno Atualizado com Sucesso.";
        } else {
            msgSuccess = `Aluno Criado com Sucesso.`;
            request = AlunoService.add(aluno);
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                this.setState({ loadingButtonSave: false });
                setTimeout(() => this.handleBack(), 3000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }))
    }

    async componentDidMount() {
        await this.props.loadContext();

        this.setState({ history: filterPersistence(this.props.location) });

        EscolaService.find(this.state.aluno.mecCode)
            .then(res => {
                const { inscricao } = res.data
                if (inscricao) {
                    this.setState({
                        ...this.state,
                        escola: res.data,
                    })

                    if (this.state.aluno.id) {
                        AlunoService.find(this.state.aluno.id)
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    aluno: {
                                        id: res.data.cdAluno,
                                        mecCode: res.data.cdMecEscola,
                                        name: res.data.nmAluno,
                                        sexo: res.data.sexo,
                                        raca: res.data.raca,
                                        dataNascimento: parseDate(res.data.dataNascimento),
                                        anoLetivo: res.data.anoLetivo,
                                        anoLetivoOriginal: res.data.cdAluno ? res.data.anoLetivo : null,
                                        nivel: res.data.nivel,
                                        tipoProva: res.data.tipoProva,
                                        notaFase1: res.data.notaFase1,
                                        notaFase2: res.data.notaFase2,
                                        exibirOptionsTipoProva: res.data.tipoProva === 'NORMAL' ? false : true
                                    },
                                    loading: false
                                });
                            })
                            .catch(err => {
                                this.Toast.setState({
                                    message: {
                                        message: "Não Foi Possível Buscar o Aluno para Edição.",
                                        type: 'error',
                                        open: true
                                    }
                                })
                                this.setState({ loading: false })
                            })
                    } else {
                        if (!this.state.escola || !this.state.escola.inscricao) {
                            this.Toast.setState({
                                message: {
                                    message: "Não é Possível Cadastrar Alunos Para esta Escola.",
                                    type: "error",
                                    open: true
                                }
                            })
                            setTimeout(() => this.handleBack(), 3000);
                        } else if (!escolaComCadastroAlunoDisponivel(this.state.escola)) {
                            this.Toast.setState({
                                message: {
                                    message: "Quantidade Máxima de Alunos Já Foi Informados no Sistema.",
                                    type: "error",
                                    open: true
                                }
                            })
                            setTimeout(() => this.handleBack(), 3000);
                        }

                        this.setState({ loading: false })
                    }
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loading: false })
            })
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: {
                history: {
                    state: this.state.history.state
                }
            }
        });
    }

    handleTipoProvaChecked = e => {

        this.setState({aluno : {
            ...this.state.aluno, 
            [e.target.name] : e.target.checked,
            tipoProva : e.target.checked ? '' : 'NORMAL',
        }});

    }

    render() {
        const page = this.state.aluno.id ? 'Editar Aluno' : 'Adicionar Aluno';
        const links = [{ path: null, name: 'Administração' }, { path: '/admin/aluno', name: 'Alunos' }];
        const contexts = this.props.contexts;

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                {this.state.loading ?
                    <Loading />
                    :
                    <>
                        <Grid item xs={12}>
                            <AlertaEscola 
                                escola={this.state.escola} 
                                contexts={this.props.contexts}
                            />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={10} lg={5}>
                                <Text
                                    required
                                    label="Nome do Aluno"
                                    name="name"
                                    value={this.state.aluno.name}
                                    error={this.state.errors.name}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.name}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <DatePicker
                                    required
                                    disableFuture
                                    maxDate={format(new Date().setYear(new Date().getYear() - 3), 'MM/dd/yy', { locale: brLocale })}
                                    minDate={format(new Date().setYear(1920), 'MM/dd/yyyy', { locale: brLocale })}
                                    label="Data de Nascimento"
                                    name='dataNascimento'
                                    value={this.state.aluno.dataNascimento}
                                    onChange={(e) => this.handleChangeDate(e, 'dataNascimento')}
                                    error={this.state.errors.dataNascimento}
                                    helperText={this.state.helpers.dataNascimento}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <SelectOption
                                    required
                                    label="Sexo"
                                    name='sexo'
                                    value={this.state.aluno.sexo}
                                    onChange={(e) => this.handleChange(e)}
                                    options={optionsSexo}
                                    hiddenblank="true"
                                    error={this.state.errors.sexo}
                                    helperText={this.state.helpers.sexo}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <SelectOption
                                    label="Raça"
                                    name='raca'
                                    value={this.state.aluno.raca}
                                    onChange={(e) => this.handleChange(e)}
                                    options={optionsRaca}
                                    blankName="Não Informar"
                                />
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2}>
                            <Grid item container spacing={3}>
                                <Grid item sm={12} lg={2}>
                                    <SelectOption
                                        required
                                        label="Ano Letivo"
                                        name='anoLetivo'
                                        value={this.state.aluno.anoLetivo}
                                        onChange={(e) => {
                                            this.handleChange(e);
                                            this.verifyChangeNivel(e);
                                        }}
                                        options={optionsAnoLetivo}
                                        hiddenblank="true"
                                        error={this.state.errors.anoLetivo}
                                        helperText={this.state.helpers.anoLetivo}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={2}>
                                    <SelectOption
                                        label="Nível"
                                        name='nivel'
                                        options={optionsNivel}
                                        value={this.state.aluno.nivel}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item container spacing={3}>
                                <Grid item sm={12} lg={3}>
                                    <Text
                                        required
                                        label="Nota Fase 1"
                                        name="notaFase1"
                                        inputProps={{
                                            maxLength: 2,
                                        }}
                                        value={this.state.aluno.notaFase1}
                                        error={this.state.errors.notaFase1}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.notaFase1}
                                    />
                                </Grid>
                                
                                {(this.state.aluno.exibirOptionsTipoProva || isPermiteCadastroAlunosProvasEspeciais(contexts)) &&
                                    <>
                                        <Grid style={{ display: 'flex', alignSelf: "center" }} item sm={12} lg={2}>
                                            <CheckBox
                                                name="exibirOptionsTipoProva"
                                                label="Aluno com necessidade especial"
                                                checked={this.state.aluno.exibirOptionsTipoProva}
                                                onChange={(e) => this.handleTipoProvaChecked(e)}
                                                disabled={!isPermiteCadastroAlunosProvasEspeciais(contexts)}
                                            />
                                        </Grid>
                                        
                                        {this.state.aluno.exibirOptionsTipoProva &&
                                            <Grid item sm={12} lg={3}>
                                                <SelectOption
                                                    required
                                                    label="Tipo de Prova Para a Fase 2"
                                                    name='tipoProva'
                                                    value={this.state.aluno.tipoProva}
                                                    onChange={(e) => this.handleChange(e)}
                                                    options={optionsTipoProva.filter(option => option.value !== 'NORMAL')}
                                                    hiddenblank="true"
                                                    error={this.state.errors.tipoProva}
                                                    helperText={this.state.helpers.tipoProva}
                                                    disabled={!isPermiteCadastroAlunosProvasEspeciais(contexts)}
                                                />
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>

                        { isPeriodoInserirNotaFase2(this.props.contexts) &&
                            <Grid container spacing={3}>
                                <Grid item container spacing={3}>
                                    <Grid item sm={12} lg={3}>
                                        <Text
                                            required
                                            label="Nota Fase 2"
                                            name="notaFase2"
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                            value={this.state.aluno.notaFase2}
                                            error={this.state.errors.notaFase2}
                                            onChange={this.handleChange}
                                            helperText={this.state.helpers.notaFase2}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        <DialogConfirm 
                            parentRef={this.setDialogConfirm}
                            handleCancel={this.handleCancelChangeNivel}
                        />

                        <ButtonsForm
                            onClick={this.handleSubmit}
                            onBack={() => this.handleBack()}
                            idFocus={`saveButton`}
                            loading={this.state.loadingButtonSave}
                        />
                    </>
                }
            </Layout>
        )
    }
};

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form));